import React from 'react';
import classes from "./MyWrapper.module.css";
import {useClasses} from "../../../hooks/useClasses";

const MyWrapper = (props) => {

    const myClasses = useClasses(props, classes)
    if(myClasses.className) {
        myClasses.className += ` ${classes.wrapper}`;
    }

    return (
        <div {...props} className={myClasses.className}>
            {props.children}
        </div>
    );
};

export default MyWrapper;
import React, {useEffect} from 'react';
import classes from "./Result.module.css";
import EditOpinion from "./EditOpinion";
import {useDispatch, useSelector} from "react-redux";

const Result = (props) => {

    const dispatch = useDispatch();
    const data = useSelector(store => store.dataReducer);
    const resultString = useSelector(store => store.resultStringReducer)

    useEffect(() => {
        printData()
    },[data])
    const printData = (data) => {
        let resultString = '';
        const convertFormt = (string) => {
            return JSON.stringify(string);
        }
        if(data && data.length > 0) {
            data.forEach(opinion => {
                resultString += `[\n\t"text" => ${convertFormt(opinion.text)}, \n\t"author" => ${convertFormt(opinion.author)},\n],\n`
            })
        }
        dispatch({type: 'NEW_STRING', value: resultString})
    }

    useEffect(() => {
        printData(data)
    }, [data])

    return (
        <>
            <div className={classes.result}>
                <pre className={classes.codeContainer}>
                    <code>
                        {resultString}
                    </code>
                </pre>
                <div>
                    {
                        data.map((opinion, index) => <EditOpinion key={index} index={index}/>)
                    }
                </div>
            </div>
        </>

    );
};

export default Result;
import React from 'react';
import MyButton from "./UI/button/MyButton";
import MyObjectForm from "./MyObjectForm";
import {useDispatch, useSelector} from "react-redux";

const EditOpinion = ({index}) => {

    const data = useSelector(state => state.dataReducer)
    const dispatch = useDispatch();
    const editOpinion = (value, index) => {
        dispatch({type: 'EDIT_OPINION', index: index, value: value})
    }

    const removeOpinion = () => {
        dispatch({type: 'REMOVE_OPINION', index: index})
    }

    return (
        <MyObjectForm submitHandler={editOpinion} index={index} opinion={data[index]}>
            <div className={'d-flex flex--center gap-4'}>
                <MyButton className={'submit'} className="test1 test2" mcss={'submit big'}>Edytuj</MyButton>
                <MyButton type={'button'} onClick={removeOpinion} className={'submit danger'} mcss={'danger'}>Usuń</MyButton>
            </div>

        </MyObjectForm>
    );
};

export default EditOpinion;
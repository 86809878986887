// Пример someReducer.js
const initialState = ''
// Начальное состояние

const resultStringReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'NEW_STRING':
            return state = action.value;
        default:
            return state;
    }
};

export default resultStringReducer;
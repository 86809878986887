import React, {useRef, useState} from 'react';
import MyInput from "./UI/input/MyInput";
import {useSelector} from "react-redux";
import classes from './MyObjectForm.module.css'

const MyObjectForm = ({submitHandler, index = null, ...props}) => {

    const data = useSelector(state => state.dataReducer)
    const emptyObject = {text: '', author: ''};
    const issetOpinion = props.opinion ? true : false;
    const [opinion, setOpinion] = useState(issetOpinion ? data[index] : emptyObject)



    const opinionHandler = (e) => {
        e.preventDefault();
        submitHandler(opinion, index)
        setOpinion(opinion);
        if(!issetOpinion) {
            setOpinion(emptyObject)
        }
    }

    return (
        <form className={classes.form} onSubmit={opinionHandler} >
            <MyInput placeholder={'text'}
                     value={opinion.text}
                     onChange={e => setOpinion({...opinion, text: e.target.value})}
            />
            <MyInput placeholder={'author'}
                     value={opinion.author}
                     onChange={e => setOpinion({...opinion, author: e.target.value})}
            />
            {props.children}
        </form>
    );
};

export default MyObjectForm;
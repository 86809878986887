// Пример someReducer.js
const initialState = localStorage.getItem('data') ? JSON.parse(localStorage.getItem('data')) : []
    // Начальное состояние

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_OPINION':
            return [...state, action.value];
        case 'EDIT_OPINION':
            return state.map((item, index) => {
                if(index === action.index) {
                    return action.value
                } return item
            })
        case 'REMOVE_OPINION' :
            return state.filter((item, index) => {
                if(index !== action.index) return item;
            })
        case 'CLEAR':
            return [];
        default:
            return state;
    }
};

export default dataReducer;
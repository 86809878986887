import './App.css';
import MyObjectForm from "./components/MyObjectForm";
import React, {useEffect, useState} from "react";
import MyWrapper from "./components/UI/wrapper/MyWrapper";
import Result from "./components/Result";
import MyButton from "./components/UI/button/MyButton";
import {useDispatch, useSelector} from "react-redux";

function App() {

    const dispatch = useDispatch();
    const data = useSelector(state => state.dataReducer)
    const resultString = useSelector(state => state.resultStringReducer)
    const [title, setTitle] = useState('')
    const [copyClass, setCopyClass] = useState('submit');
    const [copyText, setCopyText] = useState('Kopiuj');
    const addOpinion = (opinion) => {
        dispatch({type: 'ADD_OPINION', value: opinion})
    }

    useEffect(() => {
        if (data.length > 0) {
            localStorage.setItem('data', JSON.stringify(data))
        } else {
            localStorage.removeItem('data');
        }
    }, [data])

    useEffect(() => {
        if (data.length === 0) {
            setTitle(<h1>Wpisz w formularz na dole strony swoją zawartość</h1>)
        } else if (data.length > 0) {
            const t1 = <h1>Żeby skopiować wynik do schowka, naciśnij przycisk "Kopiuj".</h1>
            const t2 = <h2>Żeby dodać następny wynik - wpisz w formularz na dole strony swoją zawartość.</h2>
            const p1 = <h2>Z prawej strony znajdują się mini-formularzę dla pojedynczej edycji opinii.</h2>
            const p2 = <h2>Jeśli chcesz zacząć od nowa - naciśnij przycisz "Wyczyszcz".</h2>
            setTitle(<>{t1}{t2}{p1}{p2}</>)
        }
    }, [data])

    const copyHandler = () => {
        navigator.clipboard.writeText(resultString).then(() => {
            const prevTitle = title;
            setCopyClass('success')
            setCopyText('Skopiowano')
            setTitle(<h1>Zawartość zapisana. Otworz plik tekstowy i naciśnij CTRL + V</h1>)
            setTimeout(() => {
                setTitle(prevTitle)
                setCopyText('Kopiuj')
                setCopyClass('submit')
            }, 5000)
        }).catch(e => {
            console.log(e)
        })
    }

    const clearHandler = () => {
        const confirm = window.confirm("Czy na pewno chcesz usunąć wszystkie dane? Nie zapisane dane nie da się odnowić")
        if (confirm) dispatch({type: 'CLEAR'});

    }

    return (
        <div className="App">
            <MyWrapper>
                <div className={'text-center title'}>{title}</div>
                <Result/>
            </MyWrapper>
            {data.length > 0 &&
                <MyWrapper className={'d-flex flex--center gap-2'}>
                    <MyButton onClick={copyHandler} mcss={copyClass}>{copyText}</MyButton>
                    <MyButton onClick={clearHandler} mcss={'danger'}>Wyczyszcz</MyButton>
                </MyWrapper>
            }
            <MyWrapper style={{marginBottom: '1rem'}}>
                <MyObjectForm submitHandler={addOpinion}>
                    <MyButton style={{margin: 'auto'}} mcss={'submit'}>Dodaj</MyButton>
                </MyObjectForm>
            </MyWrapper>
        </div>
    );
}

export default App;

import React from 'react';
import classes from "./MyButton.module.css";
import {useClasses} from "../../../hooks/useClasses";
const MyButton = (props) => {

    const myClasses = useClasses(props, classes)


    return (
        <button {...props} className={myClasses.className}>
            {props.children}
        </button>
    );
};

export default MyButton;
import React from 'react';
import classes from "./MyInput.module.css";
import {useClasses} from "../../../hooks/useClasses";
const MyInput = (props) => {

    const myClasses = useClasses(props, classes)
    if(myClasses.className) {
        myClasses.className += ` ${classes.input}`;
    }

    return (
        <input {...props} className={myClasses.className}/>
    );
};

export default MyInput;
import { useEffect, useState } from "react";

export const useClasses = (props, classes = {}) => {
    useEffect(() => {
        updateProps();
    }, [props, classes]);

    const [myProps, setMyProps] = useState({});

    const updateProps = () => {
        const { 'mcss': classNamesProp, ...restProps } = props;

        const classNamesArr = classNamesProp ? classNamesProp.split(" ") : [];

        // Объединение классов из CSS-модуля и переданных классов
        const classNames = classNamesArr
            .map((cl) => (classes[cl] ? classes[cl] : cl))
            .join(" ");

        // Удаление дубликатов классов
        const uniqueClassNames = Array.from(new Set(classNames.split(" "))).join(" ");

        // Создание нового объекта с обновленными пропсами
        const updatedProps = {
            ...restProps,
            className: `${restProps.className || ""} ${uniqueClassNames}`,
        };

        setMyProps(updatedProps);
    };

    return myProps;
};
